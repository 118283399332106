<template>
    <div class="LossDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form :id="formId" ref="form" label-width="80px" width="auto" size="small" :inline="true">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构类型">
                            <el-input v-model="form.deptTypeName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input v-model="form.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-abstract :type="adjustAbstractType" v-model="form.abstractCode" disabled />
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="form.creator" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input v-model="form.createTime" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="盘点编码" v-if="form.stockCheckCode">
                            <el-input
                                placeholder="请填写"
                                v-model="form.stockCheckCode"
                                style="width: 215px"
                                readonly
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" readonly />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 32px">
                <el-button type="primary" @click="handleAudit" v-if="auditDetail" size="small"> 审核详情 </el-button>
                <el-button style="float: right; margin-top: 3px" size="mini" @click="handlePrint">打印</el-button>
                <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.stock.loss.review'" />
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                :data="form.details"
                size="mini"
                show-summary
                :highlight-current-row="true"
                max-height="520"
                v-loading="tableLoading"
                :id="tableId"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="goodsCode" label="商品编码" width="180" />
                <el-table-column label="商品名称" width="240">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.bars" label="条码" width="120">
                    <template slot-scope="scope">
                        <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.category" label="类目" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.unit" label="单位" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.brand" label="品牌" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="showPrices"
                    v-if="hasPrivilege('biz.price.wholeSale.query') && showInPrice()"
                    label="批发价快照"
                    width="100"
                />
                <el-table-column prop="counts" label="调整数量" width="80" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from 'js/Util';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import EfAbstract from 'components/EfAbstract';
import EfRemark from 'components/EfRemark';
import GoodsCommon from 'js/GoodsCommon';
import PickupReturnAudit from '../../../components/PickupReturnAudit';
import UrlUtils from '../../../../js/UrlUtils';
import MoneyUtils from 'js/MoneyUtils';

export default {
    name: 'LossDetail',
    components: { EfAbstract, EfRemark, PickupReturnAudit },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            baseUrl: this.$efApi.stockLossApi.baseUrl,
            adjustAbstractType: StockBizCommon.AdjustAbstractTypeEnum.LOSS,
            audit: {
                code: this.code,
                bizCode: '',
                auditDate: Util.nowFormatDate(),
                advice: '',
                remark: '',
            },
            auditDetail: false,
            form: {
                code: '',
                deptCode: '',
                deptName: '',
                deptTypeName: '',
                abstractCode: '',
                abstractName: '',
                stockCheckCode: '',
                reviewStatus: '',
                remark: '',
                createTime: '',
                creator: '',
                details: [],
            },
            goodsDetailMap: new Map(),
            tableLoading: true,

            //id标识
            formId: 'form',
            tableId: 'table',
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await this.$efApi.stockLossApi.detail(this.code);
            Util.copyProperties(rst, this.form);
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                rst,
                this.detailParamPropertyArrForShow()
            );
            this.form.details = arr.detailParams;
            this.goodsDetailMap = arr.goodsDetails;
            this.tableLoading = false;
        })();
        UrlUtils.QueryRemote(this, `${this.baseUrl}/reviewListData/` + this.code, (rst) => {
            if (rst.reviewList.length > 0) {
                this.auditDetail = true;
            }
        });
    },
    methods: {
        handlePrint() {
            this.printIdSelectorHtml(
                [
                    { id: this.formId, type: 'form' },
                    { id: this.tableId, type: 'table' },
                ],
                this.$route.meta.title || ''
            );
        },
        detailParamPropertyArrForShow() {
            return [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter('wholeSalePrices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
        },
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.code, this.baseUrl, this.stockStatus);
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
